/* You can add global styles to this file, and also import other style files */
@import '~swiper/swiper.min.css';
@import '~swiper/modules/navigation/navigation.min.css';
@import '~swiper/modules/pagination/pagination.min.css';


html, body { height: 100%; margin: 0px;
  padding: 0px;}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


:root {

  /* Yellows */
  --Foundation-Amarillo-amarillo-50: #FEFBED;
  --Foundation-Amarillo-amarillo-100: #FCF1C7;
  --Foundation-Amarillo-amarillo-200: #FBEAAC;
  --Foundation-Amarillo-amarillo-300: #F9E186;
  --Foundation-Amarillo-amarillo-400: #F8DB6F;
  --Foundation-Amarillo-amarillo-500: #F6D24B;
  --Foundation-Amarillo-amarillo-600: #E0BF44;
  --Foundation-Amarillo-amarillo-700: #AF9535;
  --Foundation-Amarillo-amarillo-800: #877429;
  --Foundation-Amarillo-amarillo-900: #675820;

  /* Whites */
  --Foundation-Blanco-blanco-50: #FEFEFE;
  --Foundation-Blanco-blanco-100: #FDFDFD;
  --Foundation-Blanco-blanco-200: #FCFCFC;
  --Foundation-Blanco-blanco-300: #FBFBFB;
  --Foundation-Blanco-blanco-400: #FAFAFA;
  --Foundation-Blanco-blanco-500: #f9f9f9;
  --Foundation-Blanco-blanco-600: #E3E3E3;
  --Foundation-Blanco-blanco-700: #B1B1B1;
  --Foundation-Blanco-blanco-800: #898989;
  --Foundation-Blanco-blanco-900: #696969;
  
  /* main Colors */
  --Foundation-main-Color-main-color-50: #e9e9ea;
  --Foundation-main-Color-main-color-100: #BABBBD;
  --Foundation-main-Color-main-color-200: #999B9D;
  --Foundation-main-Color-main-color-300: #6A6D70;
  --Foundation-main-Color-main-color-400: #4D5154;
  --Foundation-main-Color-main-color-500: #212529;
  --Foundation-main-Color-main-color-600: #1E2225;
  --Foundation-main-Color-main-color-700: #171A1D;
  --Foundation-main-Color-main-color-800: #121417;
  --Foundation-main-Color-main-color-900: #0E1011;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  border-radius: 5px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #f6d24c;
  border-radius: 5px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: transparent;
  width: 100px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #f6d24c;
  border-radius: 5px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #C49C0A;
  border-radius: 5px;
}